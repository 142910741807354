.selected_ingredients {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selected_ingredients::-webkit-scrollbar {
  width: 8px;
}

.selected_ingredients::-webkit-scrollbar-thumb {
  border: 0px;
  border-radius: 0px;
}