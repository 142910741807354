.main_section {
    max-height: calc(100vh - var(--offset-base-size) * 60);
    min-height: 540px;
    padding-right: 8px;
}

.scrollable {
    overflow: auto;
}

.scrollable::-webkit-scrollbar {
    width: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
    border: 0px;
    border-radius: 0px;
}