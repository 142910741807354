.modal {
  border-radius: 40px;
  border: 1px solid var(--interface-modal-edge, rgba(76, 76, 255, 0.20));
  background: var(--interface-modal-bg, #1C1C21);

  /* card/x-large */
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04), 0px 24px 32px 0px rgba(0, 0, 0, 0.04);
  padding: calc(var(--offset-base-size) * 10);
  width: 100%;
  max-width: 720px;
  max-height: 718px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  min-height: 64px;
  display: flex;
  align-items: center;

}

.content {
  max-width: 520px;
  margin: 0 auto;
}
.orderDetailContent {
  margin-top: -28px;
}