:root {
  --max-container-width: 1296px
}

* {
  box-sizing: border-box;
}

.main {
  width: 100%;
  max-width: var(--max-container-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: calc(var(--offset-base-size) * 14);
}

.container {
  width: 100%;
  max-width: var(--max-container-width);
  margin: 0 auto;
}

.main-page {
  width: 100%;
  max-width: var(--max-container-width);
  margin: 0 auto;
  min-height: calc(100vh - var(--offset-base-size) * 22);
}

.profile-page {
  width: 100%;
  max-width: var(--max-container-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-column-gap: calc(var(--offset-base-size) * 15);
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.clear-link-styles {
  text-decoration: none;
}

.text-left {
  text-align: left;
}

.profile-orders-block {
  overflow: auto;
  max-height: calc(100vh - var(--offset-base-size) * 60);
  min-height: 540px;
  padding-right: 8px;
}

.profile-orders-block::-webkit-scrollbar {
  width: 8px !important;
}

.profile-orders-block::-webkit-scrollbar-thumb {
  border: 0px !important;
  border-radius: 0px !important;
}

.burger-drop-active .constructor-element {}

.burger-can_drop .constructor-element {
  -webkit-animation: fadeInFromNone 1s infinite;
  -moz-animation: fadeInFromNone 1s infinite;
  -o-animation: fadeInFromNone 1s infinite;
  animation: fadeInFromNone 1s infinite;
  transition: 0.2s;
}

.link {
  color: var(--interface-accent, #4C4CFF);
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
}

.link:hover {
  color: white;
}

.light-nav-link {
  color: var(--text-dark-grey-secondary-text, #8585AD);
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
  background: none;
  border: 0;
}

.clear-button {
  background: none;
  border: 0;
}

.light-nav-link:hover,
.light-nav-link.active {
  color: white;
}

.w-mx-640 {
  max-width: 640px;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.2;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.2;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}